<template>
  <div>
    <div class="field">
      <sqr-select
        label="relation_status"
        :value="relation.status"
        :options="statuses"
        @select="fieldSet({field: 'status', value: $event})"
      />
    </div>
    <div class="field">
      <sqr-input-text
        label="relation_company"
        :value="relation.company"
        @change="fieldSet({field: 'company', value: $event})"
      />
    </div>
    <div class="field">
      <sqr-input-text
        label="relation_civility"
        :value="relation.civility"
        @change="fieldSet({field: 'civility', value: $event})"
      />
    </div>
    <div class="field">
      <div class="columns">
        <div class="column">
          <sqr-input-text
            label="relation_given_name"
            :value="relation.givenName"
            @change="fieldSet({field: 'givenName', value: $event})"
          />
        </div>
        <div class="column">
          <sqr-input-text
            label="relation_family_name"
            :value="relation.familyName"
            @change="fieldSet({field: 'familyName', value: $event})"
          />
        </div>
      </div>
    </div>
    <div class="field">
      <sqr-input-date
        label="relation_birth_date"
        :value="relation.birthDate"
        @change="fieldSet({field: 'birthDate', value: $event})"
      />
    </div>
    <div class="field">
      <sqr-input-text
        label="relation_nationality"
        :value="relation.nationality"
        @change="fieldSet({field: 'nationality', value: $event})"
      />
    </div>
    <div class="field">
      <sqr-input-text
        label="relation_residence_permit"
        :value="relation.residencePermit"
        @change="fieldSet({field: 'residencePermit', value: $event})"
      />
    </div>

  </div>
</template>

<script>
import SqrSelect from '../sqrd/SqrSelect';
import SqrInputText from '../sqrd/SqrInputText';
import SqrInputDate from '../sqrd/SqrInputDate';

export default {
  name: 'RelationNameForm',
  components: {SqrSelect, SqrInputText, SqrInputDate},
  props: {
    relation: {type: Object}
  },
  computed: {
    statuses () {
      return ['prospect', 'customer'].map(id => ({id, label: this.$t('relation_status_' + id)}));
    }
  },
  methods: {
    fieldSet (payload) {
      return this.$emit('field-set', payload);
    }
  }
}
</script>
