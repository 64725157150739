<template>
  <main>
    <section class="section">
      <div class="container2">
        <nav class="breadcrumb is-medium" aria-label="breadcrumbs">
          <ul>
            <sqr-crumb name="broker" :bid="bid">{{
              $t('breadcrumb_broker', broker)
            }}</sqr-crumb>
            <sqr-crumb
              name="relations"
              :bid="bid"
              label="breadcrumb_relations"
            />
            <sqr-crumb
              name="relations-add"
              :bid="bid"
              label="breadcrumb_relations_add"
            />
          </ul>
        </nav>
      </div>
    </section>

    <section class="section" v-if="record">
      <div class="container">
        <div class="columns is-centered">
          <div class="column is-12-mobile is-9-tablet is-4-desktop">
            <relation-name-form
              class="field"
              :relation="record"
              @field-set="fieldSet($event)"
            />
            <sqr-error-note class="field" :error="saveError" />
            <div class="field">
              <div class="control">
                <div class="buttons is-pulled-right">
                  <sqr-button
                    label="buttons_cancel"
                    color="light"
                    size="medium"
                    @click="$router.back()"
                  />
                  <sqr-button
                    icon="save"
                    label="relations_add_create"
                    color="primary"
                    size="medium"
                    @click="saveAndGo()"
                    :is-loading="saving"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import { includes, props } from 'ramda';
import broker from './broker';
import RelationNameForm from '@/components/RelationNameForm';

export default {
  name: 'RelationsAdd',
  mixins: [broker],
  components: { RelationNameForm },
  computed: {
    ...mapGetters('brokers', ['broker']),
    ...mapGetters('relation', ['record', 'saving', 'saveError'])
  },
  mounted() {
    this.init({
      path: ['brokers', this.bid],
      record: {
        status: 'prospect',
        archived: false
      }
    });
  },
  methods: {
    ...mapMutations('relation', ['init', 'fieldSet']),
    ...mapActions('relation', ['save']),
    ...mapActions('relationsIndex', ['reindex']),
    async saveAndGo() {
      try {
        const name = props(['company', 'givenName', 'familyName'], this.record)
          .filter(v => Boolean(v))
          .join(' ');
        this.fieldSet({ field: 'name', value: name });
        const {id} = await this.save({});
        const bid = this.bid;
        await this.reindex({bid, id, doc: this.record});
        this.$router.replace({ name: 'relation', params: { bid, rid: id }});
      } catch (error) {
        return Promise.reject(error);
      }
    }
  }
  // name
  // address
  // coordinates
  // payment
  // driver
  // tags
  // policies
};
</script>
