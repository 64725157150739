export default {
  model: {
    prop: 'value',
    event: 'select'
  },
  props: {
    value: {type: String},
    options: {type: Array, default: () => []},
    valueKey: {type: String, default: 'id'},
    iconKey: {type: String, default: 'icon'},
    labelKey: {type: String, default: 'label'},
    helpKey: {type: String, default: 'help'}
  },
  computed: {
    mappedOptions () {
      let valueKey = this.valueKey;
      let iconKey = this.iconKey;
      let labelKey = this.labelKey;
      let helpKey = this.helpKey;
      return this.options.map(opt => ({
        value: opt[valueKey],
        icon: opt[iconKey],
        label: opt[labelKey],
        help: opt[helpKey],
        selected: this.value === opt[valueKey],
        disabled: opt.disabled,
        orig: opt
      }));
    }
  },
  methods: {
    change (optOrEvent) {
      if (this.disabled) return;
      let value = optOrEvent.target
        ? optOrEvent.target.value
        : optOrEvent.value;
      let opt = this.mappedOptions.find(o => o.value === value);
      if (opt && !opt.disabled) {
        this.$emit('select', opt.value, opt.orig);
        this.localValue = opt.value;
      }
    }
  }
};
